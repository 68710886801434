ion-searchbar.oscia-search input {
	--border-radius: 8px;
}

ion-searchbar.oscia-search input:placeholder-shown {
	--box-shadow: 0px 0px 5px 2px var(--ion-color-oscia-yellow); /*  rgba(0,0,0,0.8);*/
}

ion-searchbar.oscia-search /*.searchbar-has-focus */ input:not(:placeholder-shown) {
	--box-shadow: 0px 0px 2px 1px var(--ion-color-oscia-green); /*  rgba(0,0,0,0.8);*/
}