#print-raw {
	height: 100vh;
	/* US Letter ratio */
	width: calc(98vh * 8.5 / 11);
	padding: 5vh;

	.header {
		height: 5vh;
		display: flex;
		gap: 1vw;
	}

	img {
		height: 5vh;
	}

	h1 {
		margin: 0;
		font-size: 3vh;
		color: var(--ion-color-oscia-green);
	}

	h2 {
		font-size: 2.5vh;
		color: var(--ion-color-oscia-green);
	}

	.field {
		margin-left: 1%;
		margin-bottom: 2vh;
		border-bottom: 1px solid lightgray;
	}

	.label {
		text-transform: uppercase;
		font-size: 1vh;
	}

	.value {
		font-size: 2vh;
	}

	.titleize.value {
		text-transform: capitalize;
	}

	@media (prefers-color-scheme: dark) {
		// force paper look on download page
		background-color: white;

		.field, .value {
			color: black;
		}
	}
}

.spinner {
	position: absolute;
	top: 1vh;
	left: 2vh;

	.spinner-message {
		color: black;
	}
}