// adapted from https://loading.io/css/

.spinner {
	display: flex;
	gap: 1vh;
	align-items: center;

	.spinner-message {
		font-size: 2vh;
	}

	.lds-ring {
		display: inline-block;
		position: relative;
		width: 4vh;
		height: 4vh;
	}

	.lds-ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;

		width: 3vh;
		height: 3vh;
		// margin: 8px;
		margin: 0.5vh;
		border: 0.6vh solid #fff;
		// border: 8px solid var(--ion-color-oscia-green);
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		// border-color: #fff transparent transparent transparent;
		border-color: var(--ion-color-oscia-green) transparent transparent transparent;
	}

	.lds-ring div:nth-child(1) {
		animation-delay: -0.45s;
	}

	.lds-ring div:nth-child(2) {
		animation-delay: -0.3s;
	}

	.lds-ring div:nth-child(3) {
		animation-delay: -0.15s;
	}

	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}