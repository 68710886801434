:root {
	--ion-color-oscia-green: #006F66;
	--ion-color-oscia-green-rgb: 0, 111, 102;
	--ion-color-oscia-green-contrast: #ffffff;
	--ion-color-oscia-green-contrast-rgb: 255, 255, 255;
	--ion-color-oscia-green-shade: #00625a;
	--ion-color-oscia-green-tint: #1a7d75;

	--ion-color-oscia-yellow: #e3c718;
	--ion-color-oscia-yellow-rgb: 227, 199, 24;
	--ion-color-oscia-yellow-contrast: #000000;
	--ion-color-oscia-yellow-contrast-rgb: 0, 0, 0;
	--ion-color-oscia-yellow-shade: #c8af15;
	--ion-color-oscia-yellow-tint: #e6cd2f;

	--ion-tab-bar-color-selected: #006F66;
	--ion-color-step-100: #cce2e0;
}

/* hide dark canada logo in default light mode */
.canada-logo img.dark-logo {
	display: none;
}

.about-contents .footnote, .about-contents .version {
	font-style: italic;
	font-size: small;
}

.about-contents .footnote {
	float: left;
}
.about-contents .version {
	float: right;
}

@media (prefers-color-scheme: dark) {
	body {
		/* 
		List dividers were printing in a dark grey on black.  
		We increase contrast with a bright green. 
		*/
		--ion-color-step-100: var(--ion-color-oscia-green-shade) !important;
	}

	/* brigthen up cap logo */
	.cap-logo img {
		filter: brightness(250%);
	}

	/* invert ontario logo */
	.ontario-logo img {
		filter: invert(1);
	}

	/* invert canada logo */
	.canada-logo img.dark-logo {
		display: unset;
	}
	.canada-logo img.light-logo {
		display: none;
	}

	/* lighten up the hyperlinks a bit */
	.about-contents a {
		color: lightskyblue;
	}
}

.ion-color-oscia-green {
	--ion-color-base: var(--ion-color-oscia-green);
	--ion-color-base-rgb: var(--ion-color-oscia-green-rgb);
	--ion-color-contrast: var(--ion-color-oscia-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-oscia-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-oscia-green-shade);
	--ion-color-tint: var(--ion-color-oscia-green-tint);
}

.ion-color-oscia-yellow {
	--ion-color-base: var(--ion-color-oscia-yellow);
	--ion-color-base-rgb: var(--ion-color-oscia-yellow-rgb);
	--ion-color-contrast: var(--ion-color-oscia-yellow-contrast);
	--ion-color-contrast-rgb: var(--ion-color-oscia-yellow-contrast-rgb);
	--ion-color-shade: var(--ion-color-oscia-yellow-shade);
	--ion-color-tint: var(--ion-color-oscia-yellow-tint);
}

.hidden {
	visibility: hidden;
}